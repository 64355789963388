<template>
	<div class="sidebar-left">
		<div class="sidebar">
			<div class="sidebar-content todo-sidebar">
				<div class="todo-app-menu">
					<div class="add-task pb-0">
						<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" block @click="$emit('close-left-sidebar')" v-b-modal.modal-add-site-user>
							Add User
						</b-button>
					</div>
					<div class="add-task pb-0">
						<Select2 placeholder="Select Site" id="siteDropdown" v-model="filterSite" :options="siteDropDownOptions" :settings="{ allowClear: true }" @change="selectSiteChangeEvent($event)" />
					</div>
					<div class="add-task pb-0">
						<Select2 placeholder="Select User" id="userDropdown" v-model="filterUser" :options="userDropDownOptions" :settings="{ allowClear: true }" @change="selectUserChangeEvent($event)" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BButton, BListGroup, BListGroupItem, BForm, BFormGroup, BFormInput, BRow, BCol } from 'bootstrap-vue'
	import Ripple from 'vue-ripple-directive'

	export default {
		directives: {
			Ripple,
		},
		components: {
			BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BButton, BListGroup, BListGroupItem, BForm, BFormGroup, BFormInput, BRow, BCol
		},
		props: {},
		data() {
			return {
				filterSite: '',
				filterUser: '',
				siteDropDownOptions: [],
				userDropDownOptions: [],
			}
		},
		created: function () {
			this.$store.commit('app/TOGGLE_OVERLAY', true); /* FOR BLUR BACKEND */
			this.fetchSitesAndUsers();
		},
		methods: {
			selectSiteChangeEvent(val) {
				this.filterSite = val;
				this.applyFilter();
			},
			selectUserChangeEvent(val) {
				this.filterUser = val;
				this.applyFilter();
			},
			fetchSitesAndUsers() {
				this.$globalAPI.get('sites-users').then((response) => {
					response = response.data;
					if (response.status) {
						for (const site of response.sites) {
							this.siteDropDownOptions.push({
								'id': site.siteId,
								'text': site.siteName
							});
						}
						for (const user of response.users) {
							this.userDropDownOptions.push({
								'id': user.id,
								'text': user.email
							});
						}
					}
					this.$store.commit('app/TOGGLE_OVERLAY', false); /* FOR BLUR BACKEND */
				});
			},
			applyFilter() {
				this.$emit('selectedFilters', {
					'user_id': this.filterUser,
					'site_id': this.filterSite,
				});
			},
		},
	}
</script>

<style lang="scss">
	ul#select2-userDropdown-results {
		word-wrap: break-word !important;
		text-overflow: inherit !important;
		white-space: normal !important;
	}

	ul#select2-siteDropdown-results {
		word-wrap: break-word !important;
		text-overflow: inherit !important;
		white-space: normal !important;
	}
</style>

<style>

</style>